@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
html {
  overflow: hidden;
  width: 100%;
  height: 100%;
  overflow-y: scroll;

}
body {
  height: 100%;
  position: fixed;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #0067FF; */
  display: flex;
  justify-content: center;
  color: #031b4e;
}

#root {
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.unstyled-button {
  border: none;
  margin: 0;
  padding: 0;
  background: none;
  cursor: pointer;
}

.unstyled-list {
  list-style: none;
	margin-left: 0;
	padding-left: 0;
}

@media screen and (max-width: 1440px) {
  span {
    font-size: 14px;
  }

  p {
    font-size: 14px;
  }

  h2 {
    font-size: 16px;
  }

  h3 {
    font-size: 14px;
  }
}

@media screen and (max-width: 992px) {
  #root {
    margin: 0;
    height: unset;
  }
}