.location-container {
    display: flex;
    flex-direction: column;
    flex-basis: 50%;
}

.location-weather__item {
    display: flex;
    align-items: center;
}

.location-weather__value {
    margin-left: 24px;
}

.location-weather__wind-force {
    padding-left: 6px;
}