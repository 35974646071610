.journey-container {
    padding: 24px 40px 32px 40px;
    align-self: flex-start;
    background: #edf0f5;
    border-radius: 8px;
    position: absolute;
    left: 24px;
    top: 24px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
    z-index: 9;
}

.title-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.title {
    margin: 0;
    margin-right: 6px;
}

@media screen and (max-width: 1440px) {
    .title {
        font-size: 24px;
    }

    .journey-container {
        padding: 12px 20px;
    }
}

@media screen and (max-width: 992px) {
    .journey-container {
        position: unset;
        top: 0;
        left: 0;
        border-radius: 0;
        width: 100%;
    }

    .title {
        text-align: left;
    }
}

.journey-input-container {
    display: flex;
    align-items: center;
    margin-left: -24px;
}

.swap-button {
    transition: all ease-in-out 200ms;
    margin-left: 12px
}

.swap-button:enabled:hover {
    /* TODO circle effect */
    transform: scale(1.1);
}