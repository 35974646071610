.position-icon {
    position: static;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: relative;
    background: #EDF0F5;
    width: 18px;
    height: 18px;
    transform-style: preserve-3d;
}

.position-icon::before {
    content: '';
    position: absolute;
    transform: translateZ(-1px);
    top: 0;
    left: 0;
    width: 18px;
    height: 18px;
    background: rgba(0, 103, 255, 0.6);
    animation: ping infinite ease-in 1600ms;
    border-radius: 50%;
}


@keyframes ping {
    0% { 
        background: rgba(0, 103, 255, 0.6);
    }
    60% {
        transform: scale(2);
        background: rgba(0, 103, 255, 0);
    }
    100% {
        background: rgba(0, 103, 255, 0);
    }
  }