.auto-complete__container {
    position: absolute;
    background-color: #F3F5F9;
    top: 100%;
    max-width: 100%;
    width: 100%;
    z-index: 5;
    border-radius: 0 0 5px 5px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
}

.item {
    background: none;
    padding: 10px 15px;
    cursor: pointer;
    border: none;
    width: 100%;
    border-top: 1px solid #edf0f5;
    text-align: left;
    transition: all ease-in-out 100ms;
    display: flex;
    align-items: center;
}

.item:hover {
    background-color: #e5e9f1;
}

.item:hover:last-child {
    border-radius: 0 0 5px 5px;
}