.custom-link {
    color: blue;
    display: flex;
    align-items: center;
    text-decoration: none;
    font-weight: 300;
    transition: all ease-in 150ms;
}

.custom-link.current:hover {
    cursor: default;
}

.custom-link:not(.current):hover {
    font-weight: 500;
}

.custom-link.current {
    color: #031b4e;
}