.seo-container {
    background-color: #fff;
    padding: 24px;
    max-width: 1200px;
    display: flex;
}

.menu-container {
    min-width: 375px;
}

.content-container {
    max-width: 650px;
}

.content-container p {
    margin-top: 0;
}

@media screen and (max-width: 992px) {
    .seo-container { 
        flex-wrap: wrap;
    }

    .content-container {
        order: 1;
    }

    .menu-container {
        min-width: unset;
    }
    
}

@media screen and (min-width: 992px) {
    .menu-container {
        padding-left: 32px;
    }
}