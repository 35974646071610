.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    gap: 64px;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    overflow: hidden;
}

@media screen and (max-width: 992px) {
    .container { 
        flex-direction: row;
        gap: 0;
        min-height: 100%;
    }
  }