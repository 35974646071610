.wind-info {
    display: flex;
    align-items: center;
}

.wind-info__percentage {
    display: inline-block;
    font-size: 28px;
    font-weight: 600;
    width: 74px;
}


@media screen and (max-width: 1440px) {
    .wind-info__percentage {
        font-size: 18px;
        width: 50px;
    }
}