input {
    border-radius: 5px;
    border: none;
    height: 40px;
    width: 100%;
    padding: 0 15px;
    border: 1px solid #646161;
    background-color: #edf0f5;
    transition: all ease-in 250ms;
}

input.with-autocomplete {
    border-radius: 5px 5px 0 0;

}

input:focus {
    outline: none;
}

.input-container {
    display: flex;
    position: relative;
    margin: 12px 0;
}