.wind-direction {
    position: absolute;
    right: 24px;
    top: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(237 240 254 / 80%);
    padding: 5px 18px 8px;
    border-radius: 16px;
}

.wind-direction__text {
    font-size: 10px;
    top: -5px;
    position: relative;
}

.weather-container {
    position: absolute;
    bottom: 24px;
    right: 24px;
    max-width: 400px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: #edf0f5;
    gap: 24px;
    z-index: 2;
    background: #0067FF;
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
    touch-action: none;
}

.wind-info__container {
    display: flex;
    flex-direction: column;
}

.location-weather__container {
    display: flex;
    justify-content: space-between;
    gap: 24px;
}

@media screen and (max-width: 1440px) {
    .weather-container {
        padding: 16px;
        gap: 0px;
    }
}

@media screen and (max-width: 992px) {
    .wind-direction {
        padding: 0;
        border-radius: 50%;
        top: 180px;
        right: 6px;
    }

    .wind-direction > div {
        width: 42px;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .wind-direction__text {
        display: none;
    }

    .weather-container {
        background: #0067FF;
        color: #fff;
        width: calc(100% - 40px);
        max-width: unset;
        border-radius: 16px 16px 0 0;
        padding: 10px 20px 20px;
        left: 0;
        top: unset;
        bottom: -100%;
        transition: all 300ms ease-in-out;
        z-index: 8;
    }

    .location-weather__container {
        flex-wrap: wrap;
        gap: 0;
    }

    .drag-weather {
        align-self: center;
        cursor: grab;
        height: 24px;
    }
}